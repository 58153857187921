<template>
  <div class='signup-dialog' v-if='signupModal'>
    <CModal title='Member Signup' :fade='true' :backdrop='false' :closeOnBackdrop='false' :show.sync='signupModal'>
      <div class='cat-head'>
        <img src='https://cdn.s777.club/web/cat-head.png'/>
        <!--<img src='https://cdn.s777.club/web/cat-head-halloween.png'/>-->
        <!--<img src='https://cdn.s777.club/web/cat-head-christmas.png'/>-->
        <p v-if='!pendingVerification'>Welcome aboard!</p>
        <p v-if='pendingVerification'>Complete your registration</p>
      </div>
      <CForm @submit='checkForm' v-if='!pendingVerification'>
        <CInput 
          v-model='email'
          placeholder='Email Address' 
          :invalidFeedback='emailInvalidFeedback'
          :isValid='validateEmail'
          v-bind:class='{ valid: isValidEmail }'
          required>
          <template #prepend-content><CIcon name='cil-envelope-closed'/></template>
        </CInput>
        <CInput 
          v-model='username'
          placeholder='Desired Username' 
          :invalidFeedback='usernameInvalidFeedback'
          :isValid='validateUsername'
          v-bind:class='{ valid: isValidUsername }'
          required>
          <template #prepend-content><CIcon name='cil-user'/></template>
        </CInput>
        <CInput 
          v-model='password'
          type='password' 
          placeholder='Password'
          invalidFeedback='6 to 16 characters'
          :isValid='validatePassword'
          v-bind:class='{ valid: isValidPassword }'
          required>
          <template #prepend-content><CIcon name='cil-shield-alt'/></template>
        </CInput>
        <div class='form-group form-actions'>
          <CButton block type='submit' class='signup-submit-btn' :disabled='formLoading'>
            <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
            Create Account
          </CButton>
        </div>
      </CForm>

      <p class='dialog-info' v-if='pendingVerification'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
          <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
          <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
        </svg> &nbsp; 
        An email has been sent to <strong>{{email}}</strong>, please click on the link contained in the verification email to complete your registration. If you did not receive a verification email, check your <strong>Spam or Junk</strong> email folders. 
      </p>
      <div class='signup-help' v-if='pendingVerification'>
        <CButton block variant='outline' color='info' size='sm' @click='resendEmail' :disabled='resendEmailLoading'><CIcon name='cil-reload' v-if='!resendEmailLoading'/><span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='resendEmailLoading'></span> Resend Verification Email</CButton>
        <!--<CButton block variant='outline' color='info' size='sm'><CIcon name='cil-envelope-closed'/> Change Email Address</CButton>-->
      </div>

      <CLink @click='login' class='forgot-password'>Already registered? Login here!</CLink>
      <div class='signup-help'>
        <CButton block variant='ghost' color='info' size='sm' @click='openSupportModal'><img src='https://cdn.s777.club/web/sidebar-contact.png' style='height:18px;'> Need help?</CButton>
      </div>
      
      <!-- Override default model footer using slot -->
      <template v-slot:footer-wrapper><div></div></template>

    </CModal>
  </div>
</template>

<script>
import apiAccount from '@/api/account'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheHeaderSignupDialog',
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  watch: {
    'signupModal': function() {
      if(!this.signupModal){
        this.toggleBodyClass('removeClass', 'noscroll');
        this.username = null
        this.password = null
        this.email = null
        this.isValidEmail = true
        this.isValidUsername = true
        this.isValidPassword = true
        this.pendingVerification = false
        this.emailInvalidFeedback = 'Email address is invalid'
        this.usernameInvalidFeedback = '4 to 16 alphanumeric characters'
        this.isEmailRetError = false
        this.isUsernameRetError = false
        this.isPasswordRetError = false
        this.resendEmailLoading = false
      }
    }
  },
  data () {
    return { 
      formLoading: false,
      signupModal: false,
      username: null,
      password: null,
      email: null,
      isValidEmail: true,
      isValidUsername: true,
      isValidPassword: true,
      pendingVerification: false,
      emailInvalidFeedback: 'Email address is invalid',
      usernameInvalidFeedback: '4 to 16 alphanumeric characters',
      isEmailRetError: false,
      isUsernameRetError: false,
      isPasswordRetError: false,
      resendEmailLoading: false
    }
  },
  created () {
    EventBus.$on('openSignupModal', () => {
      this.openSignupModal()
    })
  },
  methods:{
    openSignupModal () {
      this.toggleBodyClass('addClass', 'noscroll');
      this.signupModal = true
    },
    login(){
      this.signupModal = false
      EventBus.$emit('openLoginModal')
    },
    openSupportModal (){
      this.signupModal = false
      EventBus.$emit('openSupportModal')
    },
    validateEmail (val) {
      if(val === null){
        this.isValidEmail = true
      }else{
        if(val && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(val)){
          if(this.isEmailRetError){
            this.isValidEmail = false
            return false
          }else{
            this.isValidEmail = true
            return true
          }
        }else{
          this.isValidEmail = false
          return false
        }
      }
    },
    validateUsername (val) { // 4 to 16 alphanumeric
      if(val === null){
        this.isValidUsername = true
      }else{
        if(val && val.length >= 4 && val.length <= 16 && /^[A-Za-z0-9]+$/.test(val)){
          if(this.isUsernameRetError){
            this.isValidUsername = false
            return false
          }else{
            this.isValidUsername = true
            return true
          }
        }else{
          this.isValidUsername = false
          return false
        }
      }
    },
    validatePassword (val) { // 6 to 16 characters
      if(val === null){
        this.isValidPassword = true
      }else{
        if(val && val.length >= 6 && val.length <= 16){
          this.isValidPassword = true
          return true
        }else{
          this.isValidPassword = false
          return false
        }
      }
    },
    checkForm: function (e) {
      
      e.preventDefault();

      if(this.email === null){ this.email = '' }
      if(this.username === null){ this.username = '' }
      if(this.password === null){ this.password = '' }

      if(this.isUsernameRetError || this.isEmailRetError){
        this.isUsernameRetError = false
        this.isEmailRetError = false
      }

      if(this.validateEmail(this.email) && this.validateUsername(this.username) && this.validatePassword(this.password)){
        
        this.formLoading = true;

        apiAccount.signup(this.email, this.username, this.password).then(response => {
          
          this.formLoading = false
          this.pendingVerification = true

        }).catch((err) => {

            this.formLoading = false

            if(err.response.data.status == 'validation.error' && err.response.data.errors){
              err.response.data.errors.forEach((error) => {
                if(error.field == 'username'){
                  this.isUsernameRetError = true
                  this.isValidUsername = false
                  this.usernameInvalidFeedback = error.message
                }
                if(error.field == 'email'){
                  this.isEmailRetError = true
                  this.isValidEmail = false
                  this.emailInvalidFeedback = error.message
                }
              });
            }

            this.$toast.error('Signup error. Please check the form and try again.', { position: 'top-center', timeout: 5000, hideProgressBar: true })

        })

      }

    },
    resendEmail() {
        
      this.resendEmailLoading = true;

      apiAccount.resendSignupEmail(this.email).then(response => {
        
        this.resendEmailLoading = false
        this.$toast.success('Email successfully resent.', { position: 'top-center', timeout: 3000, hideProgressBar: true })

      }).catch((err) => {

        this.resendEmailLoading = false
        if (err.response.data.errorMsg) {
          this.$toast.error(err.response.data.errorMsg, {
            position: 'top-center',
            timeout: 5000,
            hideProgressBar: true
          })
        }else{
          this.$toast.error('Error resending email. Please contact support for assistance.', { position: 'top-center', timeout: 5000, hideProgressBar: true })
        }

      })

    },
    toggleBodyClass(addRemoveClass, className) {
      //window.scrollTo(0,0)
      const el = document.body
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  }
}
</script>