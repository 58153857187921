<template>
  <div class='forgot-password-dialog' v-if='forgotPasswordModal'>
    <CModal title='Forgot Password' :fade='true' :backdrop='false' :closeOnBackdrop='false' :show.sync='forgotPasswordModal'>
      <div class='cat-head'>
        <img src='https://cdn.s777.club/web/cat-head.png'/>
        <!--<img src='https://cdn.s777.club/web/cat-head-halloween.png'/>-->
        <!--<img src='https://cdn.s777.club/web/cat-head-christmas.png'/>-->
        <p>Forgot your password?</p>
      </div>
      <CForm @submit='submitForm' v-if='!pendingVerification'>
        <CInput required placeholder='Enter your email address' v-model='emailInput.val' v-bind:class="{ valid: validateInput(emailInput.val, 'email') !== false }" :invalidFeedback="invalidFeedback('email')" :isValid="validateInput(emailInput.val, 'email')">
          <template #prepend-content><CIcon name='cil-envelope-closed'/></template>
        </CInput>
        <div class='form-group form-actions'>
          <CButton block type='submit' class='login-submit-btn' :disabled='formLoading'>
            <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
            Reset Password
          </CButton>
        </div>
      </CForm>

      <p class='dialog-info' v-if='pendingVerification'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
          <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
          <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
        </svg> &nbsp; 
        An email has been sent to <strong>{{emailInput.val}}</strong>, please click on the link contained in the verification email to complete your password reset. If you did not receive a verification email, check your <strong>Spam or Junk</strong> email folders. 
      </p>

      <div class='signup-help'>
        <CButton block variant='ghost' color='info' size='sm' @click='openSupportModal'><img src='https://cdn.s777.club/web/sidebar-contact.png' style='height:18px;'> Need help?</CButton>
      </div>

      <!-- Override default model footer using slot -->
      <template v-slot:footer-wrapper><div></div></template>

    </CModal>
  </div>
</template>

<script>
import apiAccount from '@/api/account'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheForgotPasswordDialog',
  watch: {
    'forgotPasswordModal': function() {
      if(!this.forgotPasswordModal){
        this.$toggleBodyClass('removeClass', 'noscroll');
        // reset form values
        this.emailInput = { val: null, serverErrMsg: null , serverErrVal: null }
        this.pendingVerification = false
      }
    }
  },
  data () {
    return { 
      formLoading: false,
      forgotPasswordModal: false,
      emailInput: { val: null, serverErrMsg: null , serverErrVal: null },
      pendingVerification: false
    }
  },
  created () {
    EventBus.$on('openForgotPasswordModal', () => {
      this.openModal()
    })
  },
  methods:{
    openModal () {
      this.$toggleBodyClass('addClass', 'noscroll');
      this.forgotPasswordModal = true
    },
    openSupportModal (){
      this.forgotPasswordModal = false
      EventBus.$emit('openSupportModal')
    },
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'email'){ // valid email address
          return (val && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(val) && val !== this.emailInput.serverErrVal) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'email'){
        return (this.emailInput.val !== this.emailInput.serverErrVal) ? 'Email address is invalid' : this.emailInput.serverErrMsg
      }
    },
    submitForm: function (e) {
    
      e.preventDefault()

      if(this.validateInput(this.emailInput.val, 'email')){
        
        this.formLoading = true
        
        apiAccount.forgetPassword(this.emailInput.val).then(response => {
          
          this.formLoading = false
          this.pendingVerification = true

        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'email'){
                this.emailInput.serverErrVal = this.emailInput.val
                this.emailInput.serverErrMsg = error.message
              }
            });
          }
          if (err.response.data.errorMsg) {
            this.$toast.error(err.response.data.errorMsg, { position: 'top-center', timeout: 5000, hideProgressBar: true })
          }else{
            this.$toast.error('Error resetting password. Please contact support for assistance.', { position: 'top-center', timeout: 5000, hideProgressBar: true })
          }
        })
      
      }

    }
  }
}
</script>