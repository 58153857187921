<template>
  <div class='footer'>

    <CContainer fluid>
      <CRow :gutters='false'>
        <CCol md='3' sm='3' xs='12'>
          <span class='logo-yellow'>super</span>
        </CCol>
        <CCol md='2' sm='3' xs='12'>
          <h4 class='accordion-header' @click='toggle($event)'>Games</h4>
          <div class='accordion-content'>
            <ul>
              <li><CLink to='/games/slots'>Slots</CLink></li>
              <li><CLink to='/games/fish-tables'>Fish Table</CLink></li>
              <li><CLink to='/games/sweepstakes'>Sweepstakes</CLink></li>
              <li><CLink to='/games/arcade'>Arcade</CLink></li>
              <li><CLink to='/games/e-casino'>E-Casino</CLink></li>
              <li><CLink to='/games/all'>Just-In</CLink></li>
              <li><CLink to='/games/all'>Trending</CLink></li>
            </ul>
          </div>
        </CCol>
        <CCol md='2' sm='3' xs='12'>
          <h4 class='accordion-header' @click='toggle($event)'>Promotions</h4>
          <div class='accordion-content'>
            <ul>
              <li><CLink to='/games/promotions'>Welcome Bonus</CLink></li>
              <li><CLink to='/games/promotions'>High Rollers</CLink></li>
              <li><CLink to='/faq'>F.A.Q</CLink></li>
            </ul>
          </div>
        </CCol>
        <CCol md='2' sm='3' xs='12' @click='toggle($event)'>
          <h4 class='accordion-header'>Support</h4>
          <div class='accordion-content'>
            <ul>
              <li><CLink href='mailto:admin@s777.club'>Email Us</CLink></li>
              <li><CLink @click='openSupportModal'>Submit Ticket</CLink></li>
              <li><CLink href='https://facebook.com/super777club' target='_blank'>Messenger</CLink></li>
            </ul>
          </div>
        </CCol>
        <CCol md='3' sm='12' xs='12'>
          <div class='rg'>
            <h4 class='section-header'>Responsible Gaming</h4>
            <div class='responsible-gaming'>
              <img src='https://cdn.s777.club/web/rg1.svg'/>
              <img src='https://cdn.s777.club/web/rg2.svg'/>
              <img src='https://cdn.s777.club/web/rg3.svg'/>
            </div>
          </div>
        </CCol>
      </CRow>
    
    
    <div class='copyright'>
      Copyright &copy; {{new Date().getFullYear()}} Super 777 Club<br/>by MicroTech Gaming<br/><br/>
      <script type='application/javascript' src='https://images.dmca.com/Badges/DMCABadgeHelper.min.js'></script>
      <script type='application/javascript' src='https://web1s.com/site-d-v3.js?id=hkwHXXJeQv'></script>
      <a href="//www.dmca.com/Protection/Status.aspx?ID=123763ff-6e9b-4d48-ac6d-20a4d707be60" title="DMCA.com Protection Status" class="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca-badge-w150-5x1-08.png?ID=123763ff-6e9b-4d48-ac6d-20a4d707be60"  alt="DMCA.com Protection Status" /></a>
    </div>

    <div v-if="$route.name == 'Home'">
      <div class='home-page-content'>
        <p>For those who are passionate about gambling, going to a casino and participating in it is their great wish. With the current era, players do not need to spend a lot of time, effort and money to move to the place to participate. The presence of S777.club has supported all problems, players just need to sit at home, log in and join. S777club is a system that specializes in providing online fish table & slot games products in the USA market.</p>
        <p>Super777 Casino has good quality, full range of game products from slots to fish table games to get real money. The system meets many new utilities, the large amount of bonuses is extremely attractive.</p>
        <h1>What Is a Fish Table Game Online?</h1>
        <p>Fish table game online is an entertainment genre that uses real money to buy weapons and hunt creatures on the ocean floor. Players participating in fish table gambling will receive bonus points from creatures, convert bonus points into money and withdraw to bank accounts. That's why this type of bet is known as the fish table game online real money.</p>
        <p>This game is loved by many people because it is easy to play, easy to join, and suitable for many audiences. Especially with the fish table games, players can join online.</p>
        <h2>Where To Play Online Fish Tables And Slots?</h2>
        <p>Currently, there are many websites that offer online fish tables and slots, but players must choose a good casino, ensuring the quality of products and betting services. If players believe, they can try the gambling experience at the website S777.club.</p>
        <p>Here, players do not have to worry, because the system is very safe, bringing the best products, in accordance with the needs and desires of the players' bets. The technical staff at S777.club combine with reputable game providers, creating the best quality slot games and online fish table products.</p>
        <p>S777.club has launched a survey for fish table online enthusiasts in the US, most of which give good reviews for the quality of the website's products and services. At the system, players can participate in all versions of the online fish table games, the quality is guaranteed from format to content.</p>
        <h2>How To Play Fish Game Gambling?</h2>
        <p>The process of joining the fish games gambling is extremely simple, players can completely do it in just a few minutes:</p>
        <p>Players Sign up an account at the S777.club, personal information of the player is provided based on the form requested by the website.</p>
        <p>Players should choose the online fish table free play versions, practice first, no deposit but the experience is still very authentic. After mastering the manipulations with remembering the payout value of each creature, the player chooses the fish table gambling game online real money version to win the big prize.</p>
        <p>Players choose the best online casino fish game at S777.club to join. At Super 777 Casino when player's sign up and deposit the first time to fish games gambling online, he will receive a 100% bonus of the player's deposit value.</p>
        <h2>How Do Fish Tables Work?</h2>
        <p>Although the playing method of the fish game gambling is simpler than other casino games, it is still guaranteed to be interesting. Players can still receive a large payout and have moments of comfortable entertainment.</p>
        <ul>
          <li>
            <strong>Start The Game and Learn The Controls</strong>
            <p>Coming to the fish games gambling online, players can choose to have no deposit or play with real money. Next, the game room will appear, the player chooses the room level from low to high. If you are a new player, you should choose an easy room to practice skills.</p>
            <p>Starting the game screen a lot of different types of fish, diverse sizes, moving speeds are not the same. Players control guns and fire bullets at them. The harder the rank, the stronger the gun's destructive power.</p>
          </li>
          <li>
            <strong>Catch Fish and Get Paid</strong>
            <p>Each shot is a bullet used to kill fish, the number of bullets the player uses is purchased with real money. If the player kills the big fish, the player gets an extremely attractive payout. Fish table gambling online creates the feeling that players want to conquer challenges, starting from small fish to extremely large creatures.</p>
            <p>Each fish will have a fixed value and a health bar will appear on the head of the fish. When the player hunts a fish, the fish's health bar will decrease by an amount equal to the damage of the bullet. The bigger the gun, the higher the efficiency, when the fish's health bar runs out, the fish dies, the player receives a bonus.</p>
            <p>As for the rare fishes, they just appear from random time, moving quite slowly. Players need to have a specific strategy to hunt these fishes. The reward the player receives will be automatically updated to the player's account by the system. This bonus amount can be withdrawn to their bank account or continued to be used to play other entertaining versions.</p>
          </li>
          <li>
            <strong>Special Features And Bonus Games</strong>
            <p>In addition to the usual guns, the fish table sweepstakes online also offers a few special options. The player can choose Auto Aim to aim at any target on the screen. Then the player's gun will automatically shoot the fish, the bullets will go through the fish if it gets in the way of the player's target. The bullet will stop when it hits the right target and the player can switch to another target.</p>
            <p>For some online sweepstakes fish table versions, the Auto Fish option will apply to certain types of fish noted in the list. Players should pay attention to the fish in the list when choosing Auto fish.</p>
          </li>
        </ul>
        <h2>How To Win Money At Fish Tables Online?</h2>
        <p>Similar to the fish table online versions of other websites, S777.club allows players to use real money to place bets. This means that if you win, you will also receive real money as a reward. The way to win the online fish table game is not difficult, because almost all games have similar rules, players can catch a lot of loot or not depending on the level and practice.</p>
        <p>In addition to providing online fish shooting game real money products, S777.club also has detailed information about each game, instructions on how to use weapons, tips for participating in fish tables online. If players have time, they can refer to these strategies, they are very useful, it improves the player's ability to win, and brings great rewards.</p>
        <ul>
          <li>
            <strong>Set Aside a large bank account</strong>
            <p>Finance is the deciding factor for the longevity of the game. If the player does not guarantee the balance of the bet, do not choose a high bet, choose a moderate bet. In other words, if a player is playing with a base bet of $0.10 with a $0.10 top gun, the player needs to have at least $100 to work.</p>
          </li>
          <li>
            <strong>Forget about the small fish</strong>
            <p>Small on-screen goals are usually easy to catch, in return they have small payouts. These are often not worth it to players who are winning. Players need a lot of time, focus on the game. Instead of getting rewards from small fish, they will prefer to hunt big fish of higher value.</p>
            <p>Players who want to achieve this goal need to use X5 and X10 guns. Then the small fish will become transparent and players will not lose focus when they mistakenly shoot them. The Auto Aim feature will come into full play when the player chooses this strategy.</p>
          </li>
          <li>
            <strong>Know when to stop shooting</strong>
            <p>Players want to beat the fish on the screen, players need to know their losses. Players will notice in the process of hunting there are very stubborn fish, their payout is very low. Players should not hunt these fish, instead invest in other creatures of higher value.</p>
            <p>Basically if the player fails to catch the original target, the player moves on to another target. The profit of the big fish will ensure that the player does not suffer a loss. That is why players should invest in rare fish even though they take a lot of time. Note that players should consider the number of bullets and the time to hunt them, to avoid wasting everything.</p>
          </li>
          <li>
            <strong>Switch between all four screens</strong>
            <p>Players remember that the fish table game has more than one location. In fact, the fish game versions have up to 4 screens connected to each other and the fish can move from one screen to another. It is important that the player captures the creature they want to hunt on the next screen.</p>
            <p>Players should learn to switch between screens, this is one way players can win. So players just need to expand the top right corner, click on the corner they want. This is really a useful tool for players to focus on the big fish, making the most of the round.</p>
          </li>
          <li>
            <strong>Take advantage of the online fish tables Sign-Up bonus</strong>
            <p>Normally, the fish tables online will give a Sign-Up bonus from $10 - $250 for new members. You can make good use of this money to increase your chances of winning.</p>
          </li>
        </ul>
        <h2>Online Fish Table Software Vendors at Super777 Club</h2>
        <p>All online fish table arcades at Super 777 Casino come from major software vendors, with high quality and safety. Such as: Joker Gaming, Vpower 777, Kaga, Playstar, Funky... All software is available at S777.club and players do not need to download.</p>
        <h2>Join The Fish Table Gambling Game Online Real Money</h2>
        <p>Players can switch to the real mode after practicing the demo mode. The mechanism and method of participation do not change, but because participating in a fish table gambling game online real money usa, the reward players receive will also be real money. Players make sure to choose the right betting level for their bankroll.</p>
        <p>Most fishing experts recommend that players start with a small scale, then increase the difficulty to high. For difficult fishes, players need to apply more strategies. The lowest payout is 0.01$ and the highest is usually 100$.</p>
        <h3>Fish Table Game Apps Download For Mobile</h3>
        <p>There is no denying the convenience when participating in a fish table game with apps such as <a href="https://s777.club/Ocean-King-Online-Real-Money">oceanking fishing</a>, dragon king are well supported on iPhone and on Android devices. </p>
        <p>The only requirement here is that you need to hold your mobile in landscape view. Games such as <a href="https://s777.club/how-to-play-golden-dragon-online-fish-table">golden dragon fish table</a> aren't optimized for portrait mode due to the way the screen is designed. </p>
        <p>When joining the game, players just need to click on the fish they want to catch and the bullets the fish. In necessary cases, you can choose Auto Aim or Auto Fish.</p>
        <h3>Fish Tables Near Me</h3>
        <p>We found that players often search for the keywords "Fish tables near me", "fish games near me", "fish table arcade games near me", to participate in fish table gambling. But do players really have to go to the place to participate? 777 fish gaming operates 24 hours, all games are available in the online version and you get <a href="https://s777.club/promotions">more promotions</a> when you join.</p>
        <h2>Fish Tables Online Cash App</h2>
        <p>We accept players participating in fish tables online to pay with a cash app. If you have any needs, please contact our customer care.</p>
        <h2>S777.Club - Bitcoin Fish Tables</h2>
        <p>Bitcoin is the best payment method at Super777, players will get more promotion with this payment method.</p>
        <h2>Fish Table Game Online - No Deposit</h2>
        <p>With the desire to bring the best customer experience, Super777 Casino offers fish table game online free play, customers will not deposit when playing and will not receive real money with this version.</p>
        <h2>S777.Club - Best Online Casino Fish Game</h2>
        <p><a href="https://s777.club/">S777.club</a> is a famous and legal online casino site in the USA, offering fish table online and slot games. The player's participation in the betting system depends on the region the player is living in, because that place will have its own rules.</p>
        <p>S777.club allows players to participate in real money and receive real money rewards. Players just need to register an account, make a deposit and join the version they love. After the end of the bet, depending on each person, they can use the bonus to continue participating or withdraw the bonus to their personal bank account.</p>
        <h3>FAQ - New Players</h3>
        <p><strong>How do I create a Super777 Club Casino account?</strong></p>
        <p>In order to create an account, all you have to do is click on the 'Signup' button located in the top right corner of the webpage (for desktop) on the top left corner of the webpage (for mobile) and then enter your details in the fields provided. Once you have done this, you will receive a confirmation email with a link. You should click on this link in order to verify your account. Once these simple steps have been completed, you're good to go!</p>
        <p><strong>What do I do if I have forgotten my password?</strong></p>
        <p>Well, it happens to the best of us, but it’s not the end of the world! The fastest way to reset your password is to click on the 'Login' button, located the 'Forgot your password?' link and then click on that. Once you have done this, you will receive an email from us which will take you to another page. Here you will be able to create a new password (preferably one you will remember!).</p>
        <p><strong>What payment methods do you accept?</strong></p>
        <p>We are currently accepting payment via Bitcoin (BTC) and Cashapp. Direct credit / debit cards are not accepted due to the vast number of fraud cases we have encountered in the past.</p>
        <p>Please follow our FB page for the latest updates: <a href='https://facebook.com/super777club' target='_blank'>@super777club</a></p>
      </div>
    </div>

    <div v-if="$route.path == '/games/slots'">
      <div class='home-page-content'>
        <p>Super777 is a casino that specializes in providing top quality slots online. There are a total of more than 2,000 slot versions played with real money at <a href="https://s777.club">S777.club</a>, giving players the opportunity to receive huge Jackpot rewards.</p>
        <h1>Overview Online Slots Game Real Money</h1>
        <p><a href="https://s777.club/games/slots">Online slots</a> are popular casino games for many players. The reason Slot online is loved by many people is because of their risk and completely random results. Players just need to bet money, click the "Spin" button and wait for the results, simple, suitable for many individuals.</p>
        <p>The online slots version has more symbols/features, taking themes from familiar stories, from fairy tales to everyday life, etc. Players always feel new, both entertained and experiencing new stories based on the corresponding symbols of the game.</p>
        <h2>Why do players play game slots online?</h2>
        <ul>
          <li>
            <p>Because the results are random, no one can interfere with the player's victory, increasing the level of chance, creating excitement and suspense when participating in slot games online.</p>
          </li>
          <li>
            <p>Players who only need to spend a small amount of money can win with rewards up to hundreds or thousands of times compared to the initial bet. Even modest rewards are attractive enough for players, enticing them to participate and try their luck.</p>
          </li>
          <li>
            <p>Slot games have become a long-standing form of entertainment in the online gambling community, with friendly themes and simple gameplay suitable for many ages.</p>
          </li>
          <li>
            <p>Casino online games create fun, suitable for entertainment, admiring the delicate designs of images and vivid sounds.</p>
          </li>
        </ul>
        <h2>The advantages of games at Super 777 Casino</h2>
        <ul>
          <li>
            <strong>Play anytime, anywhere</strong>
            <p>With SUPER 777's slot online platform, players can just say goodbye to traditional casinos. Now with just a mobile phone, players can freely experience anytime, anywhere, in any situation or outfit they want.</p>
          </li>
          <li>
            <strong>A secure and safe platform</strong>
            <p>SUPER 777 is a famous online casino, actively operating in the United states, specializing in providing slot games from major game publishers. Therefore, all player activities are kept confidential, personal information is stored and encrypted with high technology. The games from SUPER 777 are extremely high quality and reputable.</p>
          </li>
          <li>
            <strong>Many game options</strong>
            <p>SUPER 777 provides a number of utilities and services to support players when participating in slots online. In addition, the product is regularly updated and upgraded over time, players have many options.</p>
          </li>
          <li>
            <strong>More payment options</strong>
            <p>Each Slot spin usually ends after just a few dozen seconds and the results are displayed immediately afterward. SUPER777 Casino quickly pays out prize money to winners, which can be digital and withdrawn into cash to a bank account. All transactions at SUPER777 are free of charge.</p>
          </li>
          <li>
            <strong>Interactive experience</strong>
            <ul>
              <li>
                <p>With modern technology, SUPER 777 creates a flexible playground where players can interact and communicate directly with each other.</p>
              </li>
              <li>
                <p>New features are continuously added to the platform, building quality and robust websites for everyone who needs.</p>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Conclusion</h2>
        <p>Here is some fundamental information about the SUPER 777 online slot games platform. If players wish to gain a clearer understanding of our services, game quality, payment options, or new visuals, we invite you to visit our website today. We wish all players the best of luck!</p>
      </div>
    </div>
  </CContainer>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheFooter',
  methods:{
    openSupportModal(){
      EventBus.$emit('openSupportModal')
    },
    toggle (event) {
      if(event.target.classList){
        event.target.classList.toggle('active')
      }
      if(event.target.nextSibling){
        event.target.nextSibling.classList.toggle('active')
      }
    }
  }
}
</script>
