<template>
  <div class='c-app'>
    
    <TheSupportDialog/>
    <TheForgotPasswordDialog/>
    <TheNewPasswordDialog/>
    <TheSignupDialog/>
    <TheLoginDialog/>
    <TheDepositDialog/>
    <TheWithdrawalDialog/>
    <TheProfileDialog/>
    <TheSocketAndLoadingOverlay/>
    <TheGameLoader/>
    <TheSidebar/>
    
    <CWrapper>
      <TheHeader/>
      <div class='c-body'>
        <main class='c-main'>
          <CContainer fluid>
            <transition name='fade' mode='out-in'>
              <router-view :key='$route.path'></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
    
  </div>
</template>

<script>
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheSupportDialog from './TheSupportDialog'
import TheForgotPasswordDialog from './TheForgotPasswordDialog'
import TheNewPasswordDialog from './TheNewPasswordDialog'
import TheSignupDialog from './TheSignupDialog'
import TheLoginDialog from './TheLoginDialog'
import TheDepositDialog from './TheDepositDialog'
import TheWithdrawalDialog from './TheWithdrawalDialog'
import TheProfileDialog from './TheProfileDialog'
import TheSocketAndLoadingOverlay from './TheSocketAndLoadingOverlay'
import TheGameLoader from './TheGameLoader'
import TheSidebar from './TheSidebar'

export default {
  name: 'TheContainer',
  components: {
    TheHeader,
    TheFooter,
    TheSupportDialog,
    TheForgotPasswordDialog,
    TheNewPasswordDialog,
    TheSignupDialog,
    TheLoginDialog,
    TheDepositDialog,
    TheWithdrawalDialog,
    TheProfileDialog,
    TheSocketAndLoadingOverlay,
    TheGameLoader,
    TheSidebar
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  created() {
    if(this.$isMobile()){
      document.body.classList.add('mobile')
    }else{
      document.body.classList.add('desktop')
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
