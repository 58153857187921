<template>
  <div class='support-dialog' v-if='supportModal'>
    <CModal title='Support' :fade='true' :backdrop='false' :closeOnBackdrop='false' :show.sync='supportModal'>
      <div class='cat-head'>
        <img src='https://cdn.s777.club/web/cat-head.png'/>
        <!--<img src='https://cdn.s777.club/web/cat-head-halloween.png'/>-->
        <!--<img src='https://cdn.s777.club/web/cat-head-christmas.png'/>-->
        <p v-if='!ticketSubmitted'>Need Help?</p>
        <p v-if='ticketSubmitted'>Help On the Way!</p>
      </div>

      <div class='justify-content-center dialog-spinner' v-show='isProfileLoading'>
        <div class='spinner-border' role='status'>
          <span class='sr-only'>Loading...</span>
        </div>
      </div>

      <CForm @submit='submitForm' v-if='!ticketSubmitted && !isProfileLoading'>
        <CInput required class='capitalize' placeholder='Full Name' v-model='fullnameInput.val' v-bind:class="{ valid: validateInput(fullnameInput.val, 'fullname') !== false }" :invalidFeedback="invalidFeedback('fullname')" :isValid="validateInput(fullnameInput.val, 'fullname')">
          <template #prepend-content><CIcon name='cil-user'/></template>
        </CInput>
        <div class='form-group vphonenumber' v-bind:class="{ valid: mobileInput.isValid, invalid: !mobileInput.isValid }">
          <VuePhoneNumberInput v-model='mobileInput.val' @update="mobileUpdate" :translations="{ phoneNumberLabel: 'Mobile Number' }"/>
          <div class='invalid-feedback'>{{invalidFeedback('mobile')}}</div>
        </div>
        <CInput required placeholder='Email address' v-model='emailInput.val' v-bind:class="{ valid: validateInput(emailInput.val, 'email') !== false }" :invalidFeedback="invalidFeedback('email')" :isValid="validateInput(emailInput.val, 'email')">
          <template #prepend-content><CIcon name='cil-envelope-closed'/></template>
        </CInput>
        <CTextarea required class='messagearea' placeholder='Enter your message and indicate preferred mode of contact (e.g. email and/or choice of messenger apps like WhatsApp, Telegram, Facebook etc.)' v-model='messageInput.val' v-bind:class="{ valid: validateInput(messageInput.val, 'message') !== false }" :invalidFeedback="invalidFeedback('message')" :isValid="validateInput(messageInput.val, 'message')">
          <template #prepend-content><CIcon name='cil-speech'/></template>
        </CTextarea>
        <div class='form-group form-actions'>
          <CButton block type='submit' class='signup-submit-btn' :disabled='formLoading'>
            <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
            Send
          </CButton>
        </div>
      </CForm>

      <p class='dialog-info' v-if='ticketSubmitted'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
          <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
          <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
        </svg> &nbsp; 
        Your support request has been <strong>successfully submitted</strong> and customer service representative will be in touch with you shortly. You may also wish to contact us <strong>via email</strong> @ <a href='mailto:admin@s777.club'>admin@s777.club</a> or reach out to us via our official Facebook page <a href='https://facebook.com/super777club' target='_blank'>@super777club</a>.
      </p>

      <div class='signup-help' v-if='ticketSubmitted'>
        <CButton block variant='outline' color='info' size='sm' @click='resetForm'><CIcon name='cil-reload'/>Submit New Request</CButton>
      </div>

      <!-- Override default model footer using slot -->
      <template v-slot:footer-wrapper><div></div></template>

    </CModal>
  </div>
</template>

<script>
import apiAccount from '@/api/account'
import { EventBus } from '@/event-bus.js'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'TheForgotPasswordDialog',
  components: {
    VuePhoneNumberInput: () => import('vue-phone-number-input')
  },
  watch: {
    'supportModal': function() {
      if(!this.supportModal){
        this.$toggleBodyClass('removeClass', 'noscroll');
        // reset form values
        this.resetForm()
      }
    }
  },
  data () {
    return { 
      formLoading: false,
      isProfileLoading: false,
      supportModal: false,
      fullnameInput: { val: null, serverErrMsg: null , serverErrVal: null },
      mobileInput: { val: null, serverErrMsg: null , serverErrVal: null, isValid: true, e164: null },
      emailInput: { val: null, serverErrMsg: null , serverErrVal: null },
      messageInput: { val: null, serverErrMsg: null , serverErrVal: null },
      ticketSubmitted: false
    }
  },
  created () {
    EventBus.$on('openSupportModal', () => {
      this.openModal()
    })
  },
  methods:{
    openModal () {
      this.$toggleBodyClass('addClass', 'noscroll');
      this.supportModal = true
      if(this.$store.state.isLoggedIn){
        this.isProfileLoading = true
        apiAccount.profile().then(response => {
          this.isProfileLoading = false
          this.emailInput.val = response.data.profile.email
          if(response.data.profile.fullname){
            this.fullnameInput.val = response.data.profile.fullname
          }
          if(response.data.profile.mobile){
            this.mobileInput.val = response.data.profile.mobile
          }
        }).catch((err) => {
          this.isProfileLoading = false
        })
      }
    },
    resetForm(){
      this.formLoading = false
      this.isProfileLoading = false
      //this.fullnameInput = { val: null, serverErrMsg: null , serverErrVal: null }
      //this.mobileInput = { val: null, serverErrMsg: null , serverErrVal: null, isValid: true, e164: null }
      //this.emailInput = { val: null, serverErrMsg: null , serverErrVal: null }
      this.messageInput = { val: null, serverErrMsg: null , serverErrVal: null }
      this.ticketSubmitted = false
    },
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'fullname'){
          return (val && /^[a-zA-Z\s]+$/.test(val) && val !== this.fullnameInput.serverErrVal) ? true : false
        }
        if(field == 'mobile'){ // valid email address
          return (val && this.mobileInput.isValid && val !== this.mobileInput.serverErrVal) ? true : false
        }
        if(field == 'email'){ // valid email address
          return (val && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(val) && val !== this.emailInput.serverErrVal) ? true : false
        }
        if(field == 'message'){
          return (val && val !== '' && val !== this.messageInput.serverErrVal) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'fullname'){
        return (this.fullnameInput.val !== this.fullnameInput.serverErrVal) ? 'Full name is invalid' : this.fullnameInput.serverErrMsg
      }
      if(field == 'mobile'){
        return (this.mobileInput.val !== this.mobileInput.serverErrVal) ? 'Mobile number is invalid' : this.mobileInput.serverErrMsg
      }
      if(field == 'email'){
        return (this.emailInput.val !== this.emailInput.serverErrVal) ? 'Email address is invalid' : this.emailInput.serverErrMsg
      }
      if(field == 'message'){
        return (this.messageInput.val !== this.messageInput.serverErrVal) ? 'Message cannot be empty' : this.messageInput.serverErrMsg
      }
    },
    submitForm: function (e) {
    
      e.preventDefault()

      if(this.validateInput(this.fullnameInput.val, 'fullname') && this.validateInput(this.mobileInput.val, 'mobile') && this.validateInput(this.emailInput.val, 'email') && this.validateInput(this.messageInput.val, 'message')){
        
        this.formLoading = true
        
        apiAccount.supportTicket(this.fullnameInput.val, this.mobileInput.e164, this.emailInput.val, this.messageInput.val).then(response => {
          
          this.formLoading = false
          this.ticketSubmitted = true

        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'fullname'){
                this.fullnameInput.serverErrVal = this.fullnameInput.val
                this.fullnameInput.serverErrMsg = error.message
              }
              if(error.field == 'mobile'){
                this.mobileInput.serverErrVal = this.mobileInput.val
                this.mobileInput.serverErrMsg = error.message
              }
              if(error.field == 'email'){
                this.emailInput.serverErrVal = this.emailInput.val
                this.emailInput.serverErrMsg = error.message
              }
              if(error.field == 'message'){
                this.messageInput.serverErrVal = this.messageInput.val
                this.messageInput.serverErrMsg = error.message
              }
            });
          }
          if (err.response.data.errorMsg) {
            this.$toast.error(err.response.data.errorMsg, { position: 'top-center', timeout: 5000, hideProgressBar: true })
          }else{
            this.$toast.error('Error submitting support request. Please retry in a few moments.', { position: 'top-center', timeout: 5000, hideProgressBar: true })
          }
        })
      
      }

    },
    mobileUpdate(val){
      if(this.mobileInput.val === null){
        this.mobileInput.isValid = true
      }else{
        this.mobileInput.isValid = val.isValid
      }
      if(val.isValid){
        this.mobileInput.e164 = val.e164
      }
    }
  }
}
</script>