import request from '@/api/axios-request'

const apiDeposits = {

  deposit(name, cashtag, amount) {
    return request({
      url: '/pl/deposits/deposit',
      method: 'post',
      data: {
        name,
        cashtag,
        amount
      }
    })
  },

  paid(id) {
    return request({
      url: '/pl/deposits/paid',
      method: 'post',
      data: {
        id
      }
    })
  },

  getLastTxns() {
    return request({
      url: '/pl/deposits/getLastTxns',
      method: 'get'
    })
  }

}

export default apiDeposits