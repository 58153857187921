<template>
  <div class='login-dialog' v-if='loginModal'>
    <CModal title='Member Login' :fade='true' :backdrop='false' :closeOnBackdrop='false' :show.sync='loginModal'>
      <div class='cat-head'>
        <img src='https://cdn.s777.club/web/cat-head.png'/>
        <!--<img src='https://cdn.s777.club/web/cat-head-halloween.png'/>-->
        <!--<img src='https://cdn.s777.club/web/cat-head-christmas.png'/>-->
        <p>Welcome back!</p>
      </div>
      <CForm @submit='submitLoginForm'>
        <CInput 
          v-model='username'
          placeholder='Username or Email' 
          invalidFeedback='Username or email address is invalid'
          :isValid='validateUsernameOrEmail'
          v-bind:class='{ valid: isValidUsernameOrEmail }'
          required>
          <template #prepend-content><CIcon name='cil-user'/></template>
        </CInput>
        <CInput 
          v-model='password'
          type='password' 
          placeholder='Password'
          invalidFeedback='Password is invalid'
          :isValid='validatePassword'
          v-bind:class='{ valid: isValidPassword }'
          required>
          <template #prepend-content><CIcon name='cil-shield-alt'/></template>
        </CInput>
        <div class='form-group form-actions'>
          <CButton block type='submit' class='login-submit-btn' :disabled='formLoading'>
            <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
            Login
          </CButton>
        </div>
      </CForm>

      <CLink @click='forgotPassword' class='forgot-password'>Forgot your password?</CLink>
      <div class='signup-help'>
        <CButton block variant='ghost' color='info' size='sm' @click='openSupportModal'><img src='https://cdn.s777.club/web/sidebar-contact.png' style='height:18px;'> Need help?</CButton>
      </div>

      <!-- Override default model footer using slot -->
      <template v-slot:footer-wrapper><div></div></template>

    </CModal>
  </div>
</template>

<script>
import apiAccount from '@/api/account'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheHeaderLoginDialog',
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  watch: {
    'loginModal': function() {
      if(!this.loginModal){
        this.toggleBodyClass('removeClass', 'noscroll');
      }
    }
  },
  data () {
    return { 
      formLoading: false,
      loginModal: false,
      username: null,
      password: null,
      isValidUsernameOrEmail: true,
      isValidPassword: true
    }
  },
  created () {
    EventBus.$on('openLoginModal', () => {
      this.openLoginModal()
    })
  },
  methods:{
    openLoginModal () {
      this.toggleBodyClass('addClass', 'noscroll');
      this.loginModal = true
    },
    forgotPassword(){
      this.loginModal = false
      EventBus.$emit('openForgotPasswordModal')
    },
    openSupportModal (){
      this.loginModal = false
      EventBus.$emit('openSupportModal')
    },
    validateUsernameOrEmail (val) { // 4 to 16 alphanumeric OR email address
      if(val === null){
        this.isValidUsernameOrEmail = true
      }else{
        if(val && ( (val.length >= 4 && val.length <= 16 && /^[A-Za-z0-9]+$/.test(val)) || ( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(val)) ) ){
          this.isValidUsernameOrEmail = true
          return true
        }else{
          this.isValidUsernameOrEmail = false
          return false
        }
      }
    },
    validatePassword (val) { // 6 to 16 characters
      if(val === null){
        this.isValidPassword = true
      }else{
        if(val && val.length >= 6 && val.length <= 16){
          this.isValidPassword = true
          return true
        }else{
          this.isValidPassword = false
          return false
        }
      }
    },
    submitLoginForm: function (e) {
    
      e.preventDefault()

      if(this.username === null){ this.username = '' }
      if(this.password === null){ this.password = '' }

      if(this.validateUsernameOrEmail(this.username) && this.validatePassword(this.password)){
        
        this.formLoading = true
        
        apiAccount.login(this.username, this.password).then(response => {
          
          this.formLoading = false
          if(response.data.status === 'success'){
            window.localStorage.setItem('authToken', response.data.authToken)
            window.localStorage.setItem('psToken', response.data.psToken)
            window.localStorage.setItem('jkToken', response.data.jkToken)
            window.localStorage.setItem('fkToken', response.data.fkToken)
            window.localStorage.setItem('kgToken', response.data.kgToken)
            window.localStorage.setItem('userID', response.data.userID)
            window.localStorage.setItem('username', response.data.username)
            window.localStorage.setItem('balance', response.data.balance)
            window.localStorage.setItem('btcAddress', response.data.btcAddress)
            this.$store.commit('set', ['isLoggedIn', true])
            EventBus.$emit('connectSocket')
            this.loginModal = false
            this.$toast.success('Successfully logged in. Welcome back!', {
              position: 'top-center',
              timeout: 3000,
              hideProgressBar: true
            })
            
          }else{
            this.$toast.error('Login unsuccessful, please check your credentials and retry.', {
              position: 'top-center',
              timeout: 5000,
              hideProgressBar: true
            })
          }

        }).catch((err) => {
          
          if(window.localStorage.getItem('authToken')){
            location.reload()
          }else{
            this.formLoading = false
            this.$toast.error('Login unsuccessful, please check your credentials and retry.', {
              position: 'top-center',
              timeout: 5000,
              hideProgressBar: true
            })
          }

        })
      
      }

    },
    toggleBodyClass(addRemoveClass, className) {
      //window.scrollTo(0,0)
      const el = document.body
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  }
}
</script>