<template>
  <div>
    <Slide :noOverlay='false' :closeOnNavigation='true' class='sidebar'>
      <div class='sidebar-top'>
        <a href='#' class='sidebar-logo'>super</a>
        <CButton block shape='pill' color='ghost' class='sidebar-signup-btn' @click='openSignupModal' v-if='!isLoggedIn'>Create Account</CButton>
        <CButton block shape='pill' color='ghost' class='sidebar-login-btn' @click='openLoginModal' v-if='!isLoggedIn'>Login</CButton>
      </div>
      <CLink to='/games/all'>
        <img src='https://cdn.s777.club/web/sidebar-list.png'/> <span>All Games</span>
      </CLink>
      
      <CLink to='/games/all'>
        <img src='https://cdn.s777.club/web/sidebar-crown.png'/> <span>Just-In</span>
      </CLink>

      <CLink to='/games/all'>
        <img src='https://cdn.s777.club/web/sidebar-fire.png'/> <span>Trending</span>
      </CLink>

      <CLink to='/promotions'>
        <img src='https://cdn.s777.club/web/sidebar-star.png'/> <span>Promotions</span>
      </CLink>

      <CLink to='/about'>
        <img src='https://cdn.s777.club/web/sidebar-info.png'/> <span>About Us</span>
      </CLink>

      <CLink to='/faq'>
        <img src='https://cdn.s777.club/web/sidebar-faq.png'/> <span>F.A.Q</span>
      </CLink>
      
      <CLink href='' @click='openSupportModal'>
        <img src='https://cdn.s777.club/web/sidebar-contact.png'/> <span class='contact'>Contact Us</span>
      </CLink>
    </Slide>
    <div class='sidebar-overlay'></div>
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheSidebar',
  components: {
    Slide
  },
  computed: {
    isLoggedIn () {
      if(this.$store.state.isLoggedIn){
        this.username = window.localStorage.getItem('username') 
        this.credits = parseFloat(window.localStorage.getItem('balance'))
      }else{
        this.username = '' 
        this.credits = 0.00
      }
      return this.$store.state.isLoggedIn
    }
  },
  data () {
    return { 
      username: '',
      credits: 0.00
    }
  },
  methods: {
    openLoginModal() {
      EventBus.$emit('openLoginModal')
    },
    openSignupModal() {
      EventBus.$emit('openSignupModal')
    },
    openSupportModal (){
      EventBus.$emit('openSupportModal')
    },
  }
}
</script>
