import request from '@/api/axios-request'

const apiWithdrawals = {

  withdraw(name, cashtag, amount) {
    return request({
      url: '/pl/withdrawals/withdraw',
      method: 'post',
      data: {
        name,
        cashtag,
        amount
      }
    })
  },

  caWithdrawal(amount) {
    return request({
      url: '/pl/withdrawals/caWithdrawal',
      method: 'post',
      data: {
        amount
      }
    })
  },

  btcWithdrawal(amount, btcAddress) {
    return request({
      url: '/pl/withdrawals/btcWithdrawal',
      method: 'post',
      data: {
        amount,
        btcAddress
      }
    })
  },

  paid(id) {
    return request({
      url: '/pl/withdrawals/paid',
      method: 'post',
      data: {
        id
      }
    })
  },

  getLastTxns() {
    return request({
      url: '/pl/withdrawals/getLastTxns',
      method: 'get'
    })
  }

}

export default apiWithdrawals