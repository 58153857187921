<template>
  <div class='new-password-dialog' v-if='newPasswordModal'>
    <CModal title='Set New Password' :fade='true' :backdrop='false' :closeOnBackdrop='false' :show.sync='newPasswordModal'>
      <div class='cat-head'>
        <img src='https://cdn.s777.club/web/cat-head.png'/>
        <!--<img src='https://cdn.s777.club/web/cat-head-halloween.png'/>-->
        <!--<img src='https://cdn.s777.club/web/cat-head-christmas.png'/>-->
        <p>Set New Password</p>
      </div>
      <CForm @submit='submitForm'>
        <CInput required type='password' placeholder='Enter new password' v-model='password1Input.val' v-bind:class="{ valid: validateInput(password1Input.val, 'password1') !== false }" :invalidFeedback="invalidFeedback('password1')" :isValid="validateInput(password1Input.val, 'password1')">
          <template #prepend-content><CIcon name='cil-shield-alt'/></template>
        </CInput>
        <CInput required type='password' placeholder='Confirm new password' v-model='password2Input.val' v-bind:class="{ valid: validateInput(password2Input.val, 'password2') !== false }" :invalidFeedback="invalidFeedback('password2')" :isValid="validateInput(password2Input.val, 'password2')">
          <template #prepend-content><CIcon name='cil-shield-alt'/></template>
        </CInput>
        <div class='form-group form-actions'>
          <CButton block type='submit' class='login-submit-btn' :disabled='formLoading'>
            <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
            Set New Password
          </CButton>
        </div>
      </CForm>

      <!-- Override default model footer using slot -->
      <template v-slot:footer-wrapper><div></div></template>

    </CModal>
  </div>
</template>

<script>
import apiAccount from '@/api/account'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheNewPasswordDialog',
  watch: {
    'newPasswordModal': function() {
      if(!this.newPasswordModal){
        this.$toggleBodyClass('removeClass', 'noscroll');
        // reset form values
        this.emailInput = { val: null, serverErrMsg: null , serverErrVal: null }
        this.pendingVerification = false
      }
    }
  },
  data () {
    return { 
      formLoading: false,
      newPasswordModal: false,
      password1Input: { val: null, serverErrMsg: null , serverErrVal: null },
      password2Input: { val: null, serverErrMsg: null , serverErrVal: null },
      token: null
    }
  },
  created () {
    EventBus.$on('openNewPasswordModal', (token) => {
      this.token = token
      this.openModal()
    })
  },
  methods:{
    openModal () {
      this.$toggleBodyClass('addClass', 'noscroll');
      this.newPasswordModal = true
    },
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'password1'){ // valid email address
          return (val && val.length >= 6 && val.length <= 16 && val !== this.password1Input.serverErrVal) ? true : false
        }
        if(field == 'password2'){ // valid email address
          return (val && val.length >= 6 && val.length <= 16 && val !== this.password2Input.serverErrVal) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'password1'){
        return (this.password1Input.val !== this.password1Input.serverErrVal) ? 'Password is invalid' : this.password1Input.serverErrMsg
      }
      if(field == 'password2'){
        return (this.password2Input.val !== this.password2Input.serverErrVal) ? 'Password is invalid' : this.password2Input.serverErrMsg
      }
    },
    submitForm: function (e) {
    
      e.preventDefault()

      if(this.validateInput(this.password1Input.val, 'password1') && this.validateInput(this.password2Input.val, 'password2') && this.password1Input.val === this.password2Input.val){
        
        this.formLoading = true
        
        apiAccount.newPassword(this.password1Input.val, this.password2Input.val, this.token).then(response => {
          
          this.formLoading = false
          this.$toast.success('Your new password has been successfully set. You may proceed to login to your game account.', { position: 'top-center', timeout: 5000, hideProgressBar: true })
          this.newPasswordModal = false
          EventBus.$emit('openLoginModal')

        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'password2'){
                this.password2Input.serverErrVal = this.password2Input.val
                this.password2Input.serverErrMsg = error.message
              }
            });
          }
          this.$toast.error('Update password error. Please check the form and try again.', { position: 'top-center', timeout: 5000, hideProgressBar: true })
        })
      
      }

    }
  }
}
</script>