<template>
  <div class='withdrawal-dialog' v-if='withdrawalModal'>
    <CModal title='Make a Withdrawal' :fade='true' :backdrop='false' :closeOnBackdrop='false' :show.sync='withdrawalModal'>

      <!--
      <div class='select-payment' v-show='!isPendingGetLastTxns'>
        <div class='left-payment'>
          <div class='payment-type' v-bind:class='{ selected: isSelectedCashapp }' @click='selectCashapp'>
            <img src='https://cdn.s777.club/web/cashapp.png'/>
            <span>Cashapp</span>
          </div>
        </div>
        <div class='right-payment'>
          <div class='payment-type' v-bind:class='{ selected: isSelectedBitcoin }' @click='selectBitcoin'>
            <img src='https://cdn.s777.club/web/bitcoin.png'/>
            <span>Bitcoin</span>
          </div>
        </div>
      </div>
      -->

      <p class='dialog-info' v-show='!isPendingGetLastTxns && !pendingCAWithdrawalTxn && !pendingBTCWithdrawalTxn'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
          <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
          <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
        </svg> &nbsp; 
        Please take a minute to read through <strong>our withdrawal terms & instructions</strong> before proceeding with making a withdrawal request.
      </p>

      <div class='justify-content-center dialog-spinner' v-show='isPendingGetLastTxns'>
        <div class='spinner-border' role='status'>
          <span class='sr-only'>Loading...</span>
        </div>
      </div>

      <div class='btc-address-container' v-if='isSelectedBitcoin'>
        <CForm @submit='submitBTCWithdrawalForm' v-show='!isPendingGetLastTxns && !pendingBTCWithdrawalTxn'>
          <CInput 
            v-model='btcAddress'
            placeholder='Receiving BTC address' 
            invalidFeedback='P2PKH or P2SH btc address types only'
            :isValid='validateBtcAddress'
            v-bind:class='{ valid: isValidBtcAddress }'
            required>
            <template #prepend-content><CIcon name='cib-bitcoin'/></template>
          </CInput>
          <CInput 
            v-model='amount'
            placeholder='Withdrawal amount (min $50 max $5000)' 
            invalidFeedback='Integer only - min 50 max 5000 - omit $ symbol'
            :isValid='validateAmount'
            v-bind:class='{ valid: isValidAmount }'
            required>
            <template #prepend-content><CIcon name='cil-money'/></template>
          </CInput>
          <div class='form-group form-actions'>
            <CButton block type='submit' class='signup-submit-btn' :disabled='formLoading'>
              <span class='spinner-border spinner-border-sm' v-if='formLoading'></span>
              Submit Withdrawal Request
            </CButton>
          </div>
        </CForm>

        <!-- For PP / PV Status -->
        <div class='pending-cashapp' v-if='pendingBTCWithdrawalTxn'>
          <p class='dialog-info'>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
              <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
            </svg> &nbsp; 
            <span>Your withdrawal request is <strong>pending processing</strong>. Kindly note that all withdrawals are processed daily in batches between <strong>9pm to 2am</strong> Eastern Standard Time.</span>
          </p>
          <p class='tag' v-if='pendingBTCWithdrawalTxn.paymentDetail'>
            <span class='amount'>${{ pendingBTCWithdrawalTxn.paymentDetail.requestedAmount }}</span><br/>
            <span class='btc-text-address'>{{ pendingBTCWithdrawalTxn.paymentDetail.receiverBtcAddress }}</span>
            <br/>
            <span class='txn' v-if='pendingBTCWithdrawalTxn.paymentDetail'>
              Requested At: {{ pendingBTCWithdrawalTxn.createdAt }}<br/>
              Reference ID: {{ pendingBTCWithdrawalTxn.txnID }}
            </span>
          </p>
          <CButton block type='submit' class='login-submit-btn'>
            <span class='spinner-border spinner-border-sm'></span>
            Pending Processing
          </CButton>
        </div>
      </div>

      <div v-if='isSelectedCashapp'>
        <CForm @submit='submitCAWithdrawalForm' v-show='!isPendingGetLastTxns && !pendingCAWithdrawalTxn'>
          <CInput 
            v-model='name'
            placeholder='Name on cashapp' 
            class='capitalize'
            invalidFeedback='Alphanumeric and spaces only'
            :isValid='validateName'
            v-bind:class='{ valid: isValidName }'
            disabled
            required>
            <template #prepend-content><CIcon name='cil-user'/></template>
          </CInput>
          <CInput 
            v-model='cashtag'
            placeholder='Cashtag (omit $ symbol)' 
            invalidFeedback='Alphanumeric only - omit $ symbol'
            :isValid='validateCashtag'
            v-bind:class='{ valid: isValidCashtag }'
            disabled
            required>
            <template #prepend-content><CIcon name='cil-dollar'/></template>
          </CInput>
          <CInput 
            v-model='amount'
            placeholder='Withdrawal amount (min $50 max $5000)' 
            invalidFeedback='Integer only - min 50 max 5000 - omit $ symbol'
            :isValid='validateAmount'
            v-bind:class='{ valid: isValidAmount }'
            required>
            <template #prepend-content><CIcon name='cil-money'/></template>
          </CInput>
          <div class='form-group form-actions'>
            <CButton block type='submit' class='signup-submit-btn' :disabled='formLoading'>
              <span class='spinner-border spinner-border-sm' v-if='formLoading'></span>
              Submit Withdrawal Request
            </CButton>
          </div>
        </CForm>

        <!-- For PP / PV Status -->
        <div class='pending-cashapp' v-if='pendingCAWithdrawalTxn'>
          <p class='dialog-info'>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
              <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
            </svg> &nbsp; 
            <span>Your withdrawal request is <strong>pending processing</strong>. Kindly note that all withdrawals are processed daily in batches between <strong>9pm to 2am</strong> Eastern Standard Time.</span>
          </p>
          <p class='tag' v-if='pendingCAWithdrawalTxn.paymentDetail'>
            <span class='amount'>${{ pendingCAWithdrawalTxn.paymentDetail.requestedAmount }}</span><br/>
            ${{ pendingCAWithdrawalTxn.paymentDetail.receiverCashtag }}
            <br/><small>( {{ pendingCAWithdrawalTxn.paymentDetail.receiverCashtagName }} )</small><br/><br/>
            <span class='txn' v-if='pendingCAWithdrawalTxn.paymentDetail'>
              Requested At: {{ pendingCAWithdrawalTxn.createdAt }}<br/>
              Reference ID: {{ pendingCAWithdrawalTxn.txnID }}
            </span>
          </p>
          <CButton block type='submit' class='login-submit-btn'>
            <span class='spinner-border spinner-border-sm'></span>
            Pending Processing
          </CButton>
        </div>

      </div>

      <div class='signup-help' v-if='!isPendingGetLastTxns'>
        <CButton block variant='ghost' color='info' size='sm' @click='openSupportModal'><img src='https://cdn.s777.club/web/sidebar-contact.png' style='height:18px;'> Need help?</CButton>
      </div>

      <!-- Override default model footer using slot -->
      <template v-slot:footer-wrapper><div></div></template>

    </CModal>
  </div>
</template>

<script>
import apiWithdrawals from '@/api/withdrawals'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheWithdrawalDialog',
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  watch: {
    'withdrawalModal': function() {
      if(!this.withdrawalModal){
        this.toggleBodyClass('removeClass', 'noscroll');
        this.name = null
        this.cashtag = null
        this.amount = null
        this.btcAddress = null
        this.isSelectedCashapp = false
        this.isSelectedBitcoin = true
        this.isPendingGetLastTxns = false
        this.pendingCAWithdrawalTxn = null
        this.pendingBTCWithdrawalTxn = null
        this.prevSuccessfulCADepositTxn = null
        this.prevSuccessfulBTCDepositTxn = null
      }
    }
  },
  data () {
    return { 
      formLoading: false,
      name: null,
      cashtag: null,
      amount: null,
      btcAddress: null,
      isValidName: true,
      isValidCashtag: true,
      isValidAmount: true,
      isValidBtcAddress: true,
      withdrawalModal: false,
      isSelectedCashapp: false,
      isSelectedBitcoin: true,
      isPendingGetLastTxns: false,
      pendingCAWithdrawalTxn: null,
      pendingBTCWithdrawalTxn: null,
      prevSuccessfulCADepositTxn: null,
      prevSuccessfulBTCDepositTxn: null
    }
  },
  created () {
    EventBus.$on('openWithdrawalModal', () => {
      this.openWithdrawalModal()
    })
  },
  methods:{
    openWithdrawalModal () {
      this.toggleBodyClass('addClass', 'noscroll');
      this.withdrawalModal = true
      this.isPendingGetLastTxns = true
      
      apiWithdrawals.getLastTxns().then(response => {
        if (response.data.status === 'success') {
          
          if(response.data.pendingCAWithdrawalTxn) {
            this.pendingCAWithdrawalTxn = response.data.pendingCAWithdrawalTxn
            this.isSelectedCashapp = true
            this.isSelectedBitcoin = false
          }
          if(response.data.pendingBTCWithdrawalTxn) {
            this.pendingBTCWithdrawalTxn = response.data.pendingBTCWithdrawalTxn
            this.isSelectedCashapp = false
            this.isSelectedBitcoin = true
          }
          if(response.data.prevSuccessfulCADepositTxn) {
            this.prevSuccessfulCADepositTxn = response.data.prevSuccessfulCADepositTxn
            this.name = response.data.prevSuccessfulCADepositTxn.paymentDetail.senderCashtagName
            this.cashtag = response.data.prevSuccessfulCADepositTxn.paymentDetail.senderCashtag
          }
          if(response.data.prevSuccessfulBTCDepositTxn) {
            this.prevSuccessfulBTCDepositTxn = response.data.prevSuccessfulBTCDepositTxn
            this.btc = response.data.prevSuccessfulBTCDepositTxn.paymentDetail.senderCashtagName
          }

          this.isPendingGetLastTxns = false
        }
      }).catch((err) => {
        this.formLoading = false
      })

    },
    openSupportModal (){
      this.withdrawalModal = false
      EventBus.$emit('openSupportModal')
    },
    selectCashapp(){
      if(this.pendingBTCWithdrawalTxn) return
      this.amount = null
      this.isSelectedCashapp = true;
      this.isSelectedBitcoin = false;
    },
    selectBitcoin(){
      if(this.pendingCAWithdrawalTxn) return
      this.amount = null
      this.isSelectedCashapp = false;
      this.isSelectedBitcoin = true;
    },
    validateName (val) {
      if(val === null){
        this.isValidName = true
      }else{
        if(val && /^[a-zA-Z0-9\s]+$/.test(val)){
          this.isValidName = true
          return true
        }else{
          this.isValidName = false
          return false
        }
      }
    },
    validateCashtag (val) {
      if(val === null){
        this.isValidCashtag = true
      }else{
        if(val && /^[a-z0-9]+$/.test(val)){
          this.isValidCashtag = true
          return true
        }else{
          this.isValidCashtag = false
          return false
        }
      }
    },
    validateAmount (val) {
      if(val === null){
        this.isValidAmount = true
      }else{
        if(val && /^\d+$/.test(val) && parseInt(val) >= 50){
          this.isValidAmount = true
          return true
        }else{
          this.isValidAmount = false
          return false
        }
      }
    },
    validateBtcAddress (val) {
      if(val === null){
        this.isValidBtcAddress = true
      }else{
        if(val && /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(val)){
          this.isValidBtcAddress = true
          return true
        }else{
          this.isValidBtcAddress = false
          return false
        }
      }
    },
    submitCAWithdrawalForm: function (e) {
      
      e.preventDefault()

      if(this.amount === null){ this.amount = '' }

      if(this.validateAmount(this.amount)){

        this.formLoading = true;
        
        apiWithdrawals.caWithdrawal(this.amount).then(response => {
          this.formLoading = false
          if (response.data.status === 'success') {
            this.pendingCAWithdrawalTxn = response.data.txn
          }
          if (response.data.errorMsg) {
            this.$toast.error(response.data.errorMsg, {
              position: 'top-center',
              timeout: 5000,
              hideProgressBar: true
            })
          }
        }).catch((err) => {
            if (err.response.data.errorMsg) {
            this.$toast.error(err.response.data.errorMsg, {
                position: 'top-center',
                timeout: 5000,
                hideProgressBar: true
              })
            }
            this.formLoading = false
        })
      }

    },
    submitBTCWithdrawalForm: function (e) {
      
      e.preventDefault()

      if(this.amount === null){ this.amount = '' }
      if(this.btcAddress === null){ this.btcAddress = '' }

      if(this.validateAmount(this.amount) && this.validateBtcAddress(this.btcAddress)){

        this.formLoading = true;
        
        apiWithdrawals.btcWithdrawal(this.amount, this.btcAddress).then(response => {
          this.formLoading = false
          if (response.data.status === 'success') {
            this.pendingBTCWithdrawalTxn = response.data.txn
          }
          if (response.data.errorMsg) {
            this.$toast.error(response.data.errorMsg, {
              position: 'top-center',
              timeout: 5000,
              hideProgressBar: true
            })
          }
        }).catch((err) => {
            if (err.response.data.errorMsg) {
            this.$toast.error(err.response.data.errorMsg, {
                position: 'top-center',
                timeout: 5000,
                hideProgressBar: true
              })
            }
            this.formLoading = false
        })
      }

    },
    toggleBodyClass(addRemoveClass, className) {
      //window.scrollTo(0,0)
      const el = document.body
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  }
}
</script>