<template>
  <CHeader :fixed='!$isMobile()' with-subheader light>
    
    <CHeaderBrand class='logo' to='/'>
      super
    </CHeaderBrand>
    
    <CHeaderNav class='mr-auto left-links'>
      <CHeaderNavItem class='d-none d-lg-block d-xl-block'>
        <CHeaderNavLink to='/home'>
          <CIcon name='cil-home'/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class='d-none d-lg-block d-xl-block'>
        <CHeaderNavLink to='/about' exact>
          About Us
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class='d-none d-lg-block d-xl-block'>
        <CHeaderNavLink to='/promotions' exact>
          Promotions
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class='d-none d-lg-block d-xl-block'>
        <CHeaderNavLink to='/faq' exact>
          F.A.Q
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    
    <CHeaderNav class='ml-auto right-links'>
      <CHeaderNavItem class='px-2 d-none d-lg-block d-xl-block'>
        <CButton shape='pill' color='ghost' @click='openLoginModal' v-if='!isLoggedIn'>Login</CButton>
      </CHeaderNavItem>
      <CHeaderNavItem class='px-2 d-block d-lg-none'>
        <CButton shape='pill' color='ghost' @click='openLoginModal' class='signup-btn' v-if='!isLoggedIn'>Login</CButton>
      </CHeaderNavItem>
      <CHeaderNavItem class='px-2 d-none d-lg-block d-xl-block'>
        <CButton shape='pill' color='ghost' @click='openSignupModal' class='signup-btn' v-if='!isLoggedIn'>Signup</CButton>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>

    <CSubheader class='px-3'>
      <CNav class='subnav'>
        <CNavItem to='/games/all'><img src='https://cdn.s777.club/web/subnav-misc.png'/> All Games</CNavItem>
        <CNavItem to='/games/slots'><img src='https://cdn.s777.club/web/subnav-slots.png'/> Slots</CNavItem>
        <CNavItem to='/games/fish-tables'><img src='https://cdn.s777.club/web/subnav-fish.png'/> Fish Table</CNavItem>
        <CNavItem to='/games/sweepstakes'><img src='https://cdn.s777.club/web/subnav-misc.png'/> Sweepstakes</CNavItem>
        <CNavItem to='/games/arcade'><img src='https://cdn.s777.club/web/subnav-game.png' style='opacity: 0.7'/> Arcade</CNavItem>
        <CNavItem to='/games/e-casino'><img src='https://cdn.s777.club/web/subnav-misc.png'/> E-Casino</CNavItem>
        <CNavItem to='/games/all'><img src='https://cdn.s777.club/web/subnav-misc.png'/> Just-In</CNavItem>
        <CNavItem to='/games/all'><img src='https://cdn.s777.club/web/subnav-misc.png'/> Trending</CNavItem>
      </CNav>
    </CSubheader>
  </CHeader>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  data () {
    return { 
    }
  },
  created () {
  },
  methods: {
    openLoginModal() {
      EventBus.$emit('openLoginModal')
    },
    openSignupModal() {
      EventBus.$emit('openSignupModal')
    }
  }
}
</script>
