<template>
  <CDropdown
    inNav
    class='c-header-nav-items'
    placement='bottom-end'
    add-menu-classes=''
    v-if='isLoggedIn'
    :show.sync='dropDownOpen'
  >
    <template #toggler>
      <CHeaderNavLink class='user'>
        <span class='d-none d-md-block d-lg-block d-xl-block'>{{ username }}</span> <CIcon name='cil-user'/>
      </CHeaderNavLink>
    </template>
    
    <CDropdownItem disabled>
      <CIcon name='cil-dollar'/> Credits
      <CBadge color='info' class='mfs-auto'>{{ creditBalance | currency}}</CBadge>
    </CDropdownItem>

    <CDropdownDivider/>

    <CDropdownItem @click='openDepositModal'>
      <img src='https://cdn.s777.club/web/plus-icon.png' style='width:20px;margin-right: 0.7rem;'/> Deposit Funds
    </CDropdownItem>

    <CDropdownItem @click='openWithdrawalModal'>
      <CIcon name='cil-transfer' /> Withdraw Funds
    </CDropdownItem>
    
    <CDropdownDivider/>
    <CDropdownItem @click='openProfileModal'>
      <CIcon name='cil-shield-alt' /> Profile Settings
    </CDropdownItem>
    <CDropdownItem @click='logout'>
      <CIcon name='cil-lock-locked' /> Logout
    </CDropdownItem>

  </CDropdown>
</template>

<script>
import apiAccount from '@/api/account'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheHeaderDropdownAccnt',
  computed: {
    isLoggedIn () {
      if(this.$store.state.isLoggedIn){
        this.username = window.localStorage.getItem('username') 
      }else{
        this.username = '' 
      }
      return this.$store.state.isLoggedIn
    },
    creditBalance () {
      return (this.$store.state.creditBalance/100)
    }
  },
  watch: {
    'dropDownOpen': function() {
      if(this.dropDownOpen){
        EventBus.$emit('creditCheck')
      }
    }
  },
  data () {
    return { 
      username: '',
      dropDownOpen: false
    }
  },
  created () {
    EventBus.$on('logout', () => {
      this.logout()
    })
    EventBus.$on('logoutClient', () => {
      this.logoutClient()
    })
  },
  methods : {
    openDepositModal() {
      EventBus.$emit('openDepositModal')
    },
    openWithdrawalModal() {
      EventBus.$emit('openWithdrawalModal')
    },
    openProfileModal() {
      EventBus.$emit('openProfileModal')
    },
    logout() {
      apiAccount.logout().then(response => {
        this.logoutClient()
      }).catch((err) => {
        this.$toast.error('An error has occurred. Please contact support for assistance.', {
          position: 'top-center',
          timeout: 5000,
          hideProgressBar: true
        })
      })
    },
    logoutClient() {
      //EventBus.$emit('closeSocket')
      window.localStorage.removeItem('authToken')
      window.localStorage.removeItem('psToken')
      window.localStorage.removeItem('jkToken')
      window.localStorage.removeItem('fkToken')
      window.localStorage.removeItem('kgToken')
      window.localStorage.removeItem('userID')
      window.localStorage.removeItem('username')
      window.localStorage.removeItem('balance')
      window.localStorage.removeItem('btcAddress')
      this.$store.commit('set', ['isLoggedIn', false])
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>