<template>
  <div>
    <div class='loading-overlay' v-if='isSocketConnecting || isEventsLoading'> <!-- v-if='isLoggedIn && this.socket && !this.socket.connected' -->
      <div class='spinner-border' role='status'>
        <span class='sr-only'>Loading...</span>
      </div>
    </div>
    <div class='dual-window' v-if='isDualWindow || isConnectionLost'>
      <div class='cat-loading'>
        <img class='loading-cat' src='https://cdn.s777.club/web/cat.png'>
        <!--<img class='loading-cat' src='https://cdn.s777.club/web/cat-halloween.png'>-->
        <!--<img class='loading-cat' src='https://cdn.s777.club/web/cat-christmas.png'>-->
        <span v-if='isDualWindow'>You have logged in from another tab</span>
        <span v-if='isConnectionLost'>Oops, connection to server lost</span>
        <div class='reconnect' @click='reload'>
          <CIcon name='cil-reload' size='xl'></CIcon>
          <span>Reconnect</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import { io } from 'socket.io-client';

export default {
  name: 'TheSocketOverlay',
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  watch: {
    '$store.state.isLoggedIn': function() {
      if(this.$store.state.isLoggedIn){
        this.initSocket()
      }else{
        this.closeSocket()
      }
    },
    'socket.connected': function() {
      if(this.socket && !this.socket.connected){
        this.isSocketConnecting = true
      }else{
        this.isSocketConnecting = false
      }
    },
    'eventsLoading': function() {
      if(this.eventsLoading.length > 0){
        this.isEventsLoading = true
      }else{
        this.isEventsLoading = false
      }
    },
  },
  data () {
    return { 
      socket: null,
      isSocketConnecting: false,
      isEventsLoading: false,
      eventsLoading: [],
      isDualWindow: false,
      isDualLogin: false,
      isConnectionLost: false
    }
  },
  created () {
    EventBus.$on('loadingTrue', (eventID) => {
      this.addLoadingEvent(eventID)
    })
    EventBus.$on('loadingFalse', (eventID) => {
      this.removeLoadingEvent(eventID)
    })
    EventBus.$on('closeSocket', () => {
      this.closeSocket()
    })
    EventBus.$on('creditCheck', (eventID) => {
      this.creditCheck()
    })
    if(this.$store.state.isLoggedIn){
      this.initSocket()
    }
  },
  methods: {
    addLoadingEvent(eventID){
      if(!this.eventsLoading.includes(eventID)){
        this.eventsLoading.push(eventID)
      }
    },
    removeLoadingEvent(eventID){
      var index = this.eventsLoading.indexOf(eventID);
      if (index !== -1) {
        this.eventsLoading.splice(index, 1);
      }
    },
    initSocket() {

      this.isSocketConnecting = true

      //this.socket = io('https://demo-api.s777.club/', { transports: ['websocket'], auth: { token: window.localStorage.getItem('authToken') }, withCredentials: true });
      this.socket = io('https://api.s777.club/', { transports: ['websocket'], auth: { token: window.localStorage.getItem('authToken') }, withCredentials: true }); //https://demo-api.s777.club/
      
      this.socket.on('connect', () => {
        this.socket.emit('creditCheck')
      });

      this.socket.on('creditupdate', (arg) => {
        this.$store.commit('set', ['creditBalance', arg.balance])
      });

      this.socket.on('caTxnSuccess', (arg) => {
        EventBus.$emit('caTxnSuccess', arg.txn)
      });

      this.socket.on('caTxnFailure', (arg) => {
        EventBus.$emit('caTxnFailure', arg.txn)
      });

      this.socket.on('btcTxnDetected', (arg) => {
        EventBus.$emit('btcTxnDetected', arg.txn)
        this.$toast.success('New bitcoin deposit detected ' + arg.txn.amountBTC + 'BTC' , { position: 'top-center', timeout: 3000, hideProgressBar: true })
      });
      this.socket.on('btcTxnConfirmed', (arg) => {
        EventBus.$emit('btcTxnConfirmed', arg.txn)
        this.$toast.success('Bitcoin deposit confirmed ' + arg.txn.amountBTC + 'BTC' , { position: 'top-center', timeout: 3000, hideProgressBar: true })
      });

      this.socket.on('dualLogin', (arg) => {
        this.isDualLogin = true
        this.$toast.error('Multiple logins detected, logging out.', {
          position: "top-center",
          timeout: 5000,
          hideProgressBar: true
        })
        EventBus.$emit('logoutClient')
        this.closeSocket()
      });

      this.socket.on('dualWindow', (arg) => {
        this.isDualWindow = true
        this.closeSocket()
      });

      this.socket.on('disconnect', (reason) => {
        if(this.socket === null || reason == 'io client disconnect'){
          console.log('socket closed')
        }else{
          console.log('reconnecting socket')
          // to do check for unauthorized call
          if(this.socket && !this.isDualWindow && !this.isDualLogin){
            this.socket.connect()
          }
        }
      });

      this.socket.on('connect_error', (err) => {
        if(err.message == 'unauthorized'){
          EventBus.$emit('logoutClient')
        }else{
          if(window.localStorage.getItem('authToken')){
            this.isConnectionLost = true
          }
        }
        this.closeSocket()
      });

    },
    closeSocket() {
      if(this.socket){
        this.socket.close()
        this.socket = null
      }
      this.isDualLogin = false
    },
    creditCheck() {
      if(this.socket && this.socket.connected){
        this.socket.emit('creditCheck');
      }
    },
    reload(){
      location.reload()
    }
  }
}
</script>
